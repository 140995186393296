import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { AnalyticsTheme } from '@/context/theme/types';

interface StatusProps {
  status: string;
}

export const FixtureStatusCell: FC<StatusProps> = ({ status }) => {
  const theme = useTheme<AnalyticsTheme>();
  return (
    <Typography
      borderRadius={1}
      padding={0.3}
      width={'fit-content'}
      bgcolor={theme.status[status.toLowerCase()]}
    >
      {status}
    </Typography>
  );
};
