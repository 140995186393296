import useSWR from 'swr';
import { useToken } from '@/service/hooks/api/useToken';
import { API } from '@/service/apiRequests/constants';
import { fetchData, Fetches } from '@/service/apiRequests/fetchService';
import { URLS } from '@/utils/env';
import { FixtureImportType } from '@/service/hooks/types/FixtureImport';
import {
  Order,
  PaginatedData,
  PaginatedResult,
} from '@/components/TableLayout/types';

export const useFixtureImport = (
  queryParams: Omit<PaginatedData, 'results'> & {
    sortBy: string;
    sortOrder: Order;
  },
) => {
  const token = useToken();
  const { data: fixtureImports, error } = useSWR<
    PaginatedResult<FixtureImportType>
  >(
    { token, endpoint: API.FIXTURES_IMPORT.GET, queryParams },
    (fetchParams: Fetches) => fetchData(fetchParams, URLS.FA_BASE_URL),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    },
  );

  return { fixtureImports, error, isLoading: !fixtureImports && !error };
};
