import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { COMMON_STRING, NO_DATA_STRING } from '@/constants/dictionary';
import { FixtureForm } from '@/components/Table/FixtureActionLatency/FixtureForm';
import { generateBarItems } from '@/components/Table/FixtureActionLatency/constants/generateBarItems';
import { FixtureActionLatency } from '@/service/hooks/types/FixtureActionLatency';

type FixtureBarProps = {
  latency?: FixtureActionLatency;
};

export const FixtureBar: FC<FixtureBarProps> = ({ latency }) => {
  const fixtureItems = generateBarItems(latency);

  return (
    <Paper
      elevation={3}
      sx={{
        p: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <FixtureForm
        label={COMMON_STRING.ID}
        tooltip={NO_DATA_STRING.FIXTURE_TOOLTIP}
      />
      <Stack
        direction='row'
        justifyContent='space-around'
        flex='1 0 0'
        divider={
          <Divider
            sx={{ bgcolor: ({ palette }) => palette.background.default }}
            orientation='vertical'
            flexItem
          />
        }
      >
        {fixtureItems.map(({ label, value }) => (
          <Box key={label}>
            <Typography
              variant='caption'
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              {label}
            </Typography>
            <Typography>{value}</Typography>
          </Box>
        ))}
      </Stack>
    </Paper>
  );
};
