import useSWR from 'swr';
import { useToken } from '@/service/hooks/api/useToken';
import { API } from '@/service/apiRequests/constants';
import { fetchData, Fetches } from '@/service/apiRequests/fetchService';
import { URLS } from '@/utils/env';

import {
  Order,
  PaginatedData,
  PaginatedResult,
} from '@/components/TableLayout/types';
import { AverageLatency } from '../types/AverageLatency';

export const useAverageLatencies = (
  queryParams: Omit<PaginatedData, 'results'> & {
    sortBy: string;
    sortOrder: Order;
    ft: string;
  },
) => {
  const token = useToken();
  const { data: averageLatencies, error } = useSWR<
    PaginatedResult<AverageLatency>
  >(
    { token, endpoint: API.Collection().collectionAnalytics.GET, queryParams },
    (fetchParams: Fetches) => fetchData(fetchParams, URLS.FA_BASE_URL),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    },
  );

  return { averageLatencies, error, isLoading: !averageLatencies && !error };
};
