import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { COMMON_STRING } from '@/constants/dictionary';
import { TABLE_PAGINATION_OPTIONS } from '@/components/TableLayout/constants';
import { initialTableFilters, Panel, UIState, UIStateContext } from './context';

export const UIStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const queryParams =
    new URLSearchParams(location.search).get(COMMON_STRING.QUERY_ID) || '';
  const [fixtureId, setFixtureId] = useState(queryParams);
  const [pageLimit, setPageLimit] = useState(TABLE_PAGINATION_OPTIONS[0]);
  const [tableFilters, setTableFilters] = useState(initialTableFilters);
  const [openedFiltersDrawer, setOpenedFiltersDrawer] = useState<Panel | null>(
    null,
  );

  const setOpenedPanelWithToggle = (panel: Panel) =>
    setOpenedFiltersDrawer(openedFiltersDrawer === panel ? null : panel);

  useEffect(() => {
    if (queryParams && queryParams !== fixtureId) setFixtureId(queryParams);
  }, [fixtureId, queryParams]);

  const providerValue: UIState = {
    openedFiltersDrawer,
    fixtureId,
    tableFilters,
    pageLimit,

    setOpenedFiltersDrawer: setOpenedPanelWithToggle,
    setPageLimit,
    setTableFilters,
  };

  return (
    <UIStateContext.Provider value={providerValue}>
      {children}
    </UIStateContext.Provider>
  );
};
