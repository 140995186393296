import { useContext, useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { enqueueSnackbar } from 'notistack';
import { Table } from '@/components/TableLayout/Table';
import { TAB_NAMES } from '@/components/TabsBar/constants';
import { useSimulations } from '@/service/hooks/api/useSimulations';
import {
  SORT_ATTRIBUTES,
  TABLE_CELLS,
} from '@/components/Table/Simulations/constants';
import { usePagination } from '@/service/hooks/table/usePagination';
import { UIStateContext } from '@/context/ui/context';
import { usePersistableFilters } from '@/service/hooks/table/usePersistableFilters';
import { parseSelectedFilters } from '@/utils/parseSelectedFilters';
import { ContextMenuItem } from '@/components/TableLayout/types';
import { Simulation } from '@/service/hooks/types/Simulation';
import { useDebounce } from '@/service/hooks/table/useDebounce';
import { ConfirmationDialog } from '@/components/ConfirmationDialog/ConfirmationDialog';
import {
  COMMON_STRING,
  CONFIRMATION_STRING,
  SUCCESS_STRING,
} from '@/constants/dictionary';
import { FiltersBar } from '@/components/Filters/FiltersBar';
import { FiltersDrawer } from '@/components/Filters/FiltersDrawer';
import { Filters } from '@/components/Filters/Filters';
import { useSelectedFilterState } from '@/service/hooks/table/useSelectedFilterState';

export const Simulations = () => {
  const [simulation, setSimulation] = useState<Simulation | null>(null);
  const [isSimulationDialogOpen, setIsSimulationDialogOpen] = useState(false);
  const [simulationAction, setSimulationAction] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { pageLimit, setPageLimit } = useContext(UIStateContext);
  const { tableFilters, setFilters } = usePersistableFilters(
    TAB_NAMES.SIMULATIONS,
  );
  const ft = useDebounce(tableFilters?.ft || '');
  const pagination = usePagination({
    limit: pageLimit,
    setLimit: setPageLimit,
    defaultSortBy: SORT_ATTRIBUTES.START_DATE,
  });
  const { simulations, isLoading, triggerSimulationAction, mutate } =
    useSimulations(
      {
        page: pagination.page,
        limit: pagination.limit,
        sortBy: pagination.sortBy,
        sortOrder: pagination.sortOrder,
        ft,
        ...parseSelectedFilters(tableFilters?.filters),
      },
      simulation?.fixtureId || '',
      simulationAction,
    );

  const { onSelect, onFiltersApply, isApplyDisabled } = useSelectedFilterState({
    onApply: (newFilters) => setFilters(newFilters),
    usedFiltersList: tableFilters?.filters,
  });

  const handleSimulationAction = async () => {
    await triggerSimulationAction();

    setIsSimulationDialogOpen(false);
    setSimulation(null);

    enqueueSnackbar(
      SUCCESS_STRING.SIMULATION(simulation?.fixtureId, simulationAction),
      { variant: 'success' },
    );

    mutate(simulations);
  };

  const menuItems: ContextMenuItem[] = [
    {
      label: 'Start',
      Icon: PlayArrowIcon,
      onClick: () => {
        setIsSimulationDialogOpen((open) => !open);
        setSimulationAction(COMMON_STRING.START);
      },
      ref: setAnchorEl,
    },
    {
      label: 'Stop',
      Icon: StopCircleIcon,
      onClick: () => {
        setIsSimulationDialogOpen((open) => !open);
        setSimulationAction(COMMON_STRING.STOP);
      },
      ref: setAnchorEl,
    },
    {
      label: 'Reset',
      Icon: RestartAltIcon,
      onClick: () => {
        setIsSimulationDialogOpen((open) => !open);
        setSimulationAction(COMMON_STRING.RESET);
      },
      ref: setAnchorEl,
    },
  ];

  return (
    <>
      <FiltersBar
        tableName={TAB_NAMES.SIMULATIONS}
        tooltip={COMMON_STRING.SEARCH_BY_NAME_SPORT}
      />
      <Table
        tableName={TAB_NAMES.SIMULATIONS}
        isLoading={isLoading}
        totalRowsCount={simulations?.pagination.results}
        menuItems={menuItems}
        rowData={simulation}
        setRowData={setSimulation}
        tableHeadCells={TABLE_CELLS}
        pagination={pagination}
        data={simulations?.data}
      />
      <FiltersDrawer
        onFiltersApply={onFiltersApply}
        isApplyDisabled={isApplyDisabled}
      >
        <Filters onSelect={onSelect} usedFiltersList={tableFilters?.filters} />
      </FiltersDrawer>
      <ConfirmationDialog
        isOpen={isSimulationDialogOpen}
        cancelHandler={() => {
          setIsSimulationDialogOpen(false);
          setSimulation(null);
        }}
        anchorEl={anchorEl}
        confirmHandler={handleSimulationAction}
        title={CONFIRMATION_STRING.TITLE.SIMULATION(simulation?.fixtureId)}
        body={CONFIRMATION_STRING.BODY.SIMULATION(simulationAction)}
        confirmLabel={simulationAction}
      />
    </>
  );
};
