import { useContext, useMemo } from 'react';
import { TAB_NAMES } from '@/components/TabsBar/constants';
import { NO_DATA_STRING } from '@/constants/dictionary';
import { generateTableCells } from '@/components/Table/FixtureActionLatency/constants/generateTableCells';
import { FixtureBar } from '@/components/Table/FixtureActionLatency/FixtureBar';
import { Table } from '@/components/TableLayout/Table';
import { FixtureAction } from '@/service/hooks/types/FixtureActionLatency';
import { UIStateContext } from '@/context/ui/context';
import { useActionsLatency } from '@/service/hooks/api/useActionsLatency';

export const FixtureActionsLatency = () => {
  const { fixtureId } = useContext(UIStateContext);

  const { latency, isLoading } = useActionsLatency(fixtureId);

  const sortedFixtureActions = useMemo(() => {
    return latency?.fixtureActions.sort(
      (a: FixtureAction, b: FixtureAction) => b.fixtureSeqNum - a.fixtureSeqNum,
    );
  }, [latency]);

  return (
    <>
      <FixtureBar latency={latency} />
      <Table
        tableName={TAB_NAMES.FIXTURE_ACTION_LATENCY}
        isLoading={isLoading}
        tableHeadCells={generateTableCells()}
        data={sortedFixtureActions}
        noDataText={NO_DATA_STRING.NO_ACTION_LATENCY}
      />
    </>
  );
};
