import { Tooltip, InputAdornment, IconButton, TextField } from '@mui/material';
import { FormEvent, useContext, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { UIStateContext } from '@/context/ui/context';
import { COMMON_STRING, DICTIONARY } from '@/constants/dictionary';
import { ROUTE_PATHS } from '@/router/constants';
import { isValidUUID } from './helpers/checkUUID';

type FixtureFormProps = {
  label: string;
  tooltip?: string;
};

export const FixtureForm = ({ label, tooltip }: FixtureFormProps) => {
  const [inputValue, setInputValue] = useState('');
  const { fixtureId } = useContext(UIStateContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (fixtureId) setInputValue(fixtureId);
  }, [fixtureId]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValidUUID(inputValue)) {
      enqueueSnackbar(DICTIONARY.ERROR.INCORRECT_FIXTURE, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'left' },
        preventDuplicate: true,
      });
      return;
    }
    navigate({
      pathname: ROUTE_PATHS.FIXTURE_ACTION_LATENCY,
      search: `${COMMON_STRING.QUERY_ID}=${inputValue}`,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Tooltip arrow title={tooltip}>
        <TextField
          type='text'
          label={label}
          id={label}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          size='small'
          sx={{
            minWidth: '25em',
          }}
          InputLabelProps={{ sx: { color: 'inherit' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  type='submit'
                  color='primary'
                  disabled={inputValue.trim().length !== 36}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    </form>
  );
};
