import { useContext, useState } from 'react';
import { Table } from '@/components/TableLayout/Table';
import { SORT_ATTRIBUTES } from '@/components/Table/FixtureImport/constants';
import { usePagination } from '@/service/hooks/table/usePagination';
import { TAB_NAMES } from '@/components/TabsBar/constants';
import { UIStateContext } from '@/context/ui/context';
import { useAverageLatencies } from '@/service/hooks/api/useAverageLatencies';
import { FiltersBar } from '@/components/Filters/FiltersBar';
import { FiltersDrawer } from '@/components/Filters/FiltersDrawer';
import { useSelectedFilterState } from '@/service/hooks/table/useSelectedFilterState';
import { usePersistableFilters } from '@/service/hooks/table/usePersistableFilters';
import { parseSelectedFilters } from '@/utils/parseSelectedFilters';
import { useDebounce } from '@/service/hooks/table/useDebounce';
import { AverageLatency } from '@/service/hooks/types/AverageLatency';
import { Filters } from '@/components/Filters/Filters';
import { COMMON_STRING } from '@/constants/dictionary';
import { TABLE_HEAD_CELLS } from './constants';

export const AverageLatencies = () => {
  const [averageLatency, setAverageLatency] = useState<AverageLatency | null>(
    null,
  );
  const { pageLimit, setPageLimit } = useContext(UIStateContext);
  const { tableFilters, setFilters } = usePersistableFilters(
    TAB_NAMES.AVERAGE_LATENCIES,
  );
  const ft = useDebounce(tableFilters?.ft || '');

  const pagination = usePagination({
    defaultSortBy: SORT_ATTRIBUTES.AVERAGE_LATENCY,
    limit: pageLimit,
    setLimit: setPageLimit,
  });
  const { averageLatencies, isLoading } = useAverageLatencies({
    limit: pagination.limit,
    page: pagination.page,
    sortOrder: pagination.sortOrder,
    sortBy: pagination.sortBy,
    ft,
    ...parseSelectedFilters(tableFilters?.filters),
  });

  const { onSelect, onFiltersApply, isApplyDisabled } = useSelectedFilterState({
    onApply: (newFilters) => setFilters(newFilters),
    usedFiltersList: tableFilters?.filters,
  });

  return (
    <>
      <FiltersBar
        tableName={TAB_NAMES.AVERAGE_LATENCIES}
        tooltip={COMMON_STRING.SEARCH_BY_NAME_SPORT_COMPETITION}
      />
      <Table
        tableName={TAB_NAMES.AVERAGE_LATENCIES}
        tableHeadCells={TABLE_HEAD_CELLS}
        data={averageLatencies?.data}
        rowData={averageLatency}
        totalRowsCount={averageLatencies?.pagination.results}
        setRowData={setAverageLatency}
        isLoading={isLoading}
        menuItems={[]}
        pagination={pagination}
      />
      <FiltersDrawer
        onFiltersApply={onFiltersApply}
        isApplyDisabled={isApplyDisabled}
      >
        <Filters onSelect={onSelect} usedFiltersList={tableFilters?.filters} />
      </FiltersDrawer>
    </>
  );
};
