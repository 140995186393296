import { HeadCell } from '@/components/TableLayout/types';
import { AverageLatency } from '@/service/hooks/types/AverageLatency';
import { FixtureCell } from '@/components/TableLayout/FixtureCell';
import { ROUTE_PATHS } from '@/router/constants';
import { COMMON_STRING } from '@/constants/dictionary';
import { ActionCell } from '@/components/TableLayout/ActionCell';
import { formatDate } from '@/utils/formatDate';
import { DATE_FORMAT } from '@/utils/constants';
import { SORT_ATTRIBUTES } from '../FixtureImport/constants';
import { FixtureStatusCell } from './FixtureStatusCell';

export const TABLE_HEAD_CELLS: HeadCell[] = [
  {
    label: 'Fixture name',
    value: ({ fixtureName }: AverageLatency) =>
      fixtureName || COMMON_STRING.N_A,
  },
  {
    label: 'Fixture Id',
    value: ({ fixtureId }: AverageLatency) => (
      <FixtureCell
        fixtureId={fixtureId}
        url={`${ROUTE_PATHS.FIXTURE_ACTION_LATENCY}?${COMMON_STRING.QUERY_ID}=`}
      />
    ),
  },
  {
    label: 'Start date',
    value: ({ startDate }: AverageLatency) =>
      startDate
        ? formatDate(startDate, DATE_FORMAT.DATE_TIME_ZONE_FORMAT)
        : COMMON_STRING.N_A,
    sortAttr: SORT_ATTRIBUTES.START_DATE,
  },
  {
    label: 'Fixture type',
    value: ({ fixtureType }: AverageLatency) =>
      fixtureType || COMMON_STRING.N_A,
  },
  {
    label: 'Fixture status',
    value: ({ status }: AverageLatency) => (
      <FixtureStatusCell status={status} />
    ),
  },
  {
    label: 'Sport',
    value: ({ sportName }: AverageLatency) => sportName || COMMON_STRING.N_A,
  },
  {
    label: 'Competition',
    value: ({ competitionName }: AverageLatency) =>
      competitionName || COMMON_STRING.N_A,
  },
  {
    label: 'Total Actions',
    value: ({ totalActions }: AverageLatency) =>
      isNaN(totalActions) ? COMMON_STRING.N_A : totalActions,
  },
  {
    label: 'Average Latency',
    value: ({ averageLatency }: AverageLatency) =>
      isNaN(averageLatency) ? COMMON_STRING.N_A : averageLatency.toFixed(4),
    sortAttr: SORT_ATTRIBUTES.AVERAGE_LATENCY,
  },
  {
    label: '',
    value: () => <ActionCell />,
  },
];
