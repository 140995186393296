import { HeadCell } from '@/components/TableLayout/types';
import { COMMON_STRING } from '@/constants/dictionary';
import { DATE_FORMAT } from '@/utils/constants';
import { formatDate } from '@/utils/formatDate';
import { FixtureAction } from '@/service/hooks/types/FixtureActionLatency';
import { formatTimeToMinSecMilli } from '../helpers/formatTimeToMinSecMilli';

export const generateTableCells = (): HeadCell[] => [
  {
    label: 'Fixture SeqNum',
    value: ({ fixtureSeqNum }: FixtureAction) => fixtureSeqNum,
  },
  {
    label: 'Scorer SeqNum',
    value: ({ scorerSeqNum }: FixtureAction) =>
      scorerSeqNum || COMMON_STRING.N_A,
  },
  {
    label: 'Send Type',
    value: ({ sendType }: FixtureAction) => sendType || COMMON_STRING.N_A,
  },
  {
    label: 'Period',
    value: ({ period }: FixtureAction) => Number(period),
  },
  {
    label: 'Action',
    value: ({ name }: FixtureAction) => name || COMMON_STRING.N_A,
  },
  {
    label: 'Created',
    value: ({ createdTimestamp }: FixtureAction) =>
      createdTimestamp
        ? formatDate(createdTimestamp, DATE_FORMAT.FULL_TIME_AND_ZONE_FORMAT)
        : COMMON_STRING.N_A,
  },
  {
    label: 'Backend Received',
    value: ({ backendReceivedTimestamp }: FixtureAction) =>
      backendReceivedTimestamp
        ? formatDate(
            backendReceivedTimestamp,
            DATE_FORMAT.FULL_TIME_AND_ZONE_FORMAT,
          )
        : COMMON_STRING.N_A,
  },
  {
    label: 'Backend Processed',
    value: ({ backendProcessedTimestamp }: FixtureAction) =>
      backendProcessedTimestamp
        ? formatDate(
            backendProcessedTimestamp,
            DATE_FORMAT.FULL_TIME_AND_ZONE_FORMAT,
          )
        : COMMON_STRING.N_A,
  },
  {
    label: 'TOTAL APP',
    value: ({ differenceBetweenCreationAndBackendReceived }: FixtureAction) =>
      differenceBetweenCreationAndBackendReceived
        ? formatTimeToMinSecMilli(differenceBetweenCreationAndBackendReceived)
        : COMMON_STRING.N_A,
  },
  {
    label: 'TOTAL Backend',
    value: ({ differenceBetweenBackendReceivedAndProcessed }: FixtureAction) =>
      differenceBetweenBackendReceivedAndProcessed
        ? formatTimeToMinSecMilli(differenceBetweenBackendReceivedAndProcessed)
        : COMMON_STRING.N_A,
  },
  {
    label: 'TOTAL',
    value: ({ differenceBetweenCreationAndBackendProcessed }: FixtureAction) =>
      differenceBetweenCreationAndBackendProcessed
        ? formatTimeToMinSecMilli(differenceBetweenCreationAndBackendProcessed)
        : COMMON_STRING.N_A,
  },
];
