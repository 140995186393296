import useSWR from 'swr';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { URLS } from '@/utils/env';
import { API } from '@/service/apiRequests/constants';
import { Fetches, fetchData } from '@/service/apiRequests/fetchService';
import { FixtureActionLatency } from '@/service/hooks/types/FixtureActionLatency';
import { DICTIONARY } from '@/constants/dictionary';
import { useToken } from './useToken';

export const useActionsLatency = (fixtureIdOrCollectionId: string) => {
  const token = useToken();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data: latency, error } = useSWR<FixtureActionLatency>(
    token && fixtureIdOrCollectionId
      ? {
          token,
          endpoint: API.Collection(fixtureIdOrCollectionId).collectionActions
            .GET,
        }
      : null,
    (fetchParams: Fetches) => fetchData(fetchParams, URLS.FA_BASE_URL),
    {
      onErrorRetry: () =>
        enqueueSnackbar(DICTIONARY.ERROR.FIXTURE_NOT_FOUND, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'left' },
          preventDuplicate: true,
          persist: true,
        }),
    },
  );

  useEffect(() => {
    if (latency) {
      closeSnackbar();
    }
  }, [latency, closeSnackbar]);

  return {
    latency,
    error,
    isLoading: !latency && !error && !!fixtureIdOrCollectionId,
  };
};
