export const API_METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
  POST: 'POST',
} as const;

export const API = {
  SCORER: {
    GET: '/Scorers/all-connected',
  },
  LIVE_DATA_CLIENTS: {
    GET: '/LiveDataClients',
    PUT: '/LiveDataClients',
  },
  USERS: {
    GET: '/Users',
  },
  SPORTS: {
    GET: '/Sports',
  },
  FIXTURES_IMPORT: { GET: '/FixturesImport' },
  Schedule: (scheduleId?: string) => {
    const path = '/Simulation/Schedules';
    return {
      simulationSchedules: {
        GET: `${path}/all`,
      },
      deleteSchedule: {
        DELETE: `${path}/${scheduleId}/delete`,
      },
    };
  },
  Collection: (collectionIdOrFixtureId?: string) => {
    const path = 'Collection';
    return {
      collectionActions: {
        GET: `/${path}/${collectionIdOrFixtureId}/collection-actions`,
      },
      collectionAnalytics: { GET: `/${path}/collection-analytics` },
    };
  },
  Simulation: (simulationAction?: string, fixtureId?: string) => {
    const path = '/Simulation';
    return {
      GET: `${path}`,
      POST: `${path}/${fixtureId}/${simulationAction}`,
    };
  },
} as const;
